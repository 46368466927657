export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/(app)/(user)": [10,[2,4]],
		"/(app)/(user)/(workspace)/accounts": [14,[2,4,5]],
		"/(app)/(user)/(workspace)/accounts/[accountId]": [15,[2,4,5]],
		"/(app)/(user)/admin": [18,[2,4]],
		"/(app)/(user)/(workspace)/categories": [17,[2,4,5]],
		"/(app)/(user)/(workspace)/dashboard": [12,[2,4,5,6]],
		"/dev": [7],
		"/(app)/(auth)/login": [9,[2,3]],
		"/(app)/(auth)/logout": [8,[2]],
		"/(app)/(user)/(workspace)/recurring": [16,[2,4,5]],
		"/(app)/(user)/(workspace)/transactions": [13,[2,4,5]],
		"/(app)/(user)/[workspaceId]": [11,[2,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';